import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import { SC_FCM_NOTIFICATION_COUNT , SC_FCM_VEHICLECRASHES_COUNT } from "../../constant/StorageConstant";

import ActiveDashboardIcon from "../../assets/images/ActiveDashboardIcon.svg";
import DashboardIcon from "../../assets/images/DashboardIcon.svg";

import ActiveUserIcon from "../../assets/images/ActiveUserIcon.svg";
import UserIcon from "../../assets/images/UserIcon.svg";

import ActiveUsersIcon from "../../assets/images/ActiveUsersIcon.svg";
import UsersIcon from "../../assets/images/UsersIcon.svg";

import ActiveSettingIcon from "../../assets/images/ActiveSettingIcon.svg";
import SettingIcon from "../../assets/images/SettingIcon.svg";

import Bell from "../../assets/images/bell.svg";
import ActiveBell from "../../assets/images/bellBlue.svg";

import CarCrashBlue from "../../assets/images/carCrashBlue.svg";
import CarCrash from "../../assets/images/carCrash.svg";
import classNames from "classnames";

import Badge from '@mui/material/Badge';

const AdminNavbar = (props) => {
  const [app, setApp] = useState(false);
  const [previouspath, setPreviouspath] = useState(null);
  const { pathname } = useLocation();
  const dropdownRef = useRef(null);
  const notificationValue = localStorage.getItem(SC_FCM_NOTIFICATION_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) : 0;
  const vehiclecrashValue = localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) : 0;

  const toggleAppDropdown = (e) => {
    e.preventDefault();
    setApp((prevApp) => !prevApp);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setApp(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if(pathname != previouspath) {
      if(previouspath == '/notifications') {
        if(parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_NOTIFICATION_COUNT,"0");
        }
      } else if (previouspath == '/vehicleCrashListPage') {
        if(parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT,"0");
        }
      }
    }
    setPreviouspath(pathname);
  },[pathname]);

  return (
    <React.Fragment>
      <div className="topnav cardHeader">
        <div className="container-fluid ps-0">

          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">

                <li className="nav-item dropdown" style={{ textAlign: "left",display:"flex",flexDirection:"column",justifyContent:"center" }} >
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3 ms-0" to="/adminDashboard" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/adminDashboard" || pathname === "/adminDashboard/ActivityUsageSummary" ? (<img src={ActiveDashboardIcon} alt="ActiveDashboardIcon" height={30} width={30} />
                      ) : (
                        <img src={DashboardIcon} alt="DashboardIcon" height={30} width={30} />
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/adminDashboard" || pathname === "/adminDashboard/ActivityUsageSummary" ? "#5156be" : "#313533" }} > {props.t("Dashboard")} </span>
                    </div>
                  </Link>
                </li>

               

                <li className="nav-item dropdown" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                  <Link className="nav-link dropdown-toggle arrow-none  p-0" to="/vehicleCrashListPageNew" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname == "/vehicleCrashListPageNew" ? (
                        <>
                        { vehiclecrashValue > 0 ?(
                          <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                            <img src={CarCrashBlue} alt="crashride" height={34} width={34} />
                          </Badge>
                        ) : (
                          <img src={CarCrashBlue} alt="crashride" height={34} width={34} />
                        )
                        }
                      </>
                      ) : (
                        <>
                          { vehiclecrashValue > 0 ?(
                            <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                              <img src={CarCrash} alt="crashride" height={34} width={34} />
                            </Badge>
                          ) : (
                            <img src={CarCrash} alt="crashride" height={34} width={34} />
                          )
                          }
                        </>
                      )}
                      
                      <span style={{ paddingLeft: "4px", color: pathname === "/vehicleCrashListPageNew" ? "#5156be" : "#313533" }} > {props.t("Vehicle Crashes")} </span>
                     
                     
                    </div>
                    <p style={{color: "#313533",fontSize:"6px",padding:"0px",margin:"0px" ,display: "flex", alignItems: "center", justifyContent: "center" ,marginTop:"-5px",marginBottom:"0px",marginLeft:"19px"}} > {props.t(`(V2 - Multi Crash Ride Detail)`)} </p>
                    
                  </Link>
                </li>

                <li className="nav-item dropdown" style={{display:"flex",flexDirection:"column",justifyContent:"center", marginLeft:"15px"}}>
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3 ms-0" to="/vehicleCrashListPage" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname == "/vehicleCrashListPage" ? (
                        <>
                        { vehiclecrashValue > 0 ?(
                          <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                            <img src={CarCrashBlue} alt="crashride" height={34} width={34} />
                          </Badge>
                        ) : (
                          <img src={CarCrashBlue} alt="crashride" height={34} width={34} />
                        )
                        }
                      </>
                      ) : (
                        <>
                          { vehiclecrashValue > 0 ?(
                            <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                              <img src={CarCrash} alt="crashride" height={34} width={34} />
                            </Badge>
                          ) : (
                            <img src={CarCrash} alt="crashride" height={34} width={34} />
                          )
                          }
                        </>
                      )}
                     
                      <span style={{ paddingLeft: "4px", color: pathname === "/vehicleCrashListPage" ? "#5156be" : "#313533" }} > {props.t("Vehicle Crashes")} </span>
                      
                      
                    </div>
                    <p style={{color: "#313533",fontSize:"6px",padding:"0px",margin:"0px" ,display: "flex", alignItems: "center", justifyContent: "center" ,marginTop:"-5px",marginBottom:"0px",marginLeft:"19px"}} > {props.t(`(V1 - Single Crash Ride Detail)`)} </p>
                  </Link>
                </li>  

                <li className="nav-item dropdown" style={{ textAlign: "left",display:"flex",flexDirection:"column",justifyContent:"center" }} >
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3 ms-0" to="/userRides" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/userRides"  ? (<img src={ActiveDashboardIcon} alt="ActiveDashboardIcon" height={30} width={30} />
                      ) : (
                        <img src={DashboardIcon} alt="DashboardIcon" height={30} width={30} />
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/userRides"  ? "#5156be" : "#313533" }} > {props.t("User Rides")} </span>
                    </div>
                  </Link>
                </li>             



              </ul>
            </Collapse>
          </nav>

        </div>
      </div>
    </React.Fragment>
  );
};

AdminNavbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(AdminNavbar))
);
