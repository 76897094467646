import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Import menuDropdown


import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


// import images
import RuduLogo from "../../assets/images/logo/motivAiLogo.svg";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useDispatch } from "react-redux";
import {
  SC_DIVISION_NAME,
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_COMPANY_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_USERNAME,
  SC_DIVISION_ID,
  DEVICE_TYPE,
  VEHICLE_TYPE,
  SC_PROFILE_IMAGE,
  SC_USER_ID,
  SC_LOGIN_COMPANY_ADDRESS_LINE_1,
  SC_LOGIN_COMPANY_ADDRESS_LINE_2,
  SC_LOGIN_COMPANY_ADDRESS_CITY,
  SC_LOGIN_COMPANY_ADDRESS_STATE,
  SC_LOGIN_COMPANY_ADDRESS_COUNTRY,
  SC_LOGIN_COMPANY_ADDRESS_POSTAL_CODE,
  SC_PROFILE_ADMIN_TO_USER,
  SC_TIMEZONE_ABBREVIATION,
  SC_TIMEZONE_DISPLAY_NAME,
  SC_TIMEZONE_ZONE_NAME,
} from "../../constant/StorageConstant";

import TopBarTimeZoneDetails from "../CommonForBoth/TopbarDropdown/TopBarTimeZoneDetails";
import MyUsersNewTable from "../../pages/Admin/NewTab/tableDataMyuser";
import TW from "../../assets/images/two_wheeler.svg";
import FW from "../../assets/images/four_wheeler.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import AdminDashboardCtrlr from "../../controller/AdminDashboardCtrlr";
import AdminLogo from "../../assets/images/LoginProfile/Admin.svg";
import SuperAdminLogo from "../../assets/images/LoginProfile/Super Admin.svg";
import UserLogo from "../../assets/images/LoginProfile/User.svg";
import CompanyDefaultImage from "../../assets/images/LoginProfile/companyDefaultImage.svg"
import WorkOrderIcon from "../../assets/images/workOrderIcon.svg"


const Header = (props) => {
  const dispatch = useDispatch();
  const { onChangeLayoutMode } = props;
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE) || "";
  const [getImage, setGetImage] = useState("");
  const [getUserImage, setGetUserImage] = useState("");
  const location = useLocation();
  const { pathname } = useLocation();
  const companyName = localStorage.getItem(SC_COMPANY_NAME) || "";
  const userName = localStorage.getItem(SC_USER_FIRST_NAME) || "";
  const userFirstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME) || "";
  const userUsername = localStorage.getItem(SC_USER_USERNAME || "");
  const divisionId = localStorage.getItem(SC_DIVISION_ID || "");
  const deviceType = localStorage.getItem(DEVICE_TYPE) || "N/A";
  const vehicleType = localStorage.getItem(VEHICLE_TYPE) || "N/A";
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE) || "";
  const deviceId = localStorage.getItem(SC_DIVISION_ID) || "";
  const userId = localStorage.getItem(SC_USER_ID) || "";
  var profileImage = localStorage.getItem(SC_PROFILE_IMAGE) || "";

  var profilePicturePathAdminToUser = localStorage.getItem(SC_PROFILE_ADMIN_TO_USER) || "";
  
  var abbreviation = localStorage.getItem(SC_TIMEZONE_ABBREVIATION) || "";
  var DisplayNAme = localStorage.getItem(SC_TIMEZONE_DISPLAY_NAME) || "";
  var ZoneName = localStorage.getItem(SC_TIMEZONE_ZONE_NAME) || "";

  var addressLine1 = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_LINE_1);
  var addressLine2 = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_LINE_2);
  var city         = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_CITY);
  var state        = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_STATE);
  var country      = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_COUNTRY);
  var postalCode   = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_POSTAL_CODE);
  
  
  addressLine1 = (addressLine1 != "undefined") ? addressLine1 : "";
  addressLine2 = (addressLine2 != "undefined") ? addressLine2 : "";
  city = (city != "undefined") ? city : "";
  state = (state != "undefined") ? state : "";
  country = (country != "undefined") ? country : "";
  postalCode = (postalCode != "undefined") ? postalCode : "";

    const [previousPath, setPreviousPath] = useState(() => localStorage.getItem("previousPath") || "");
    
      useEffect(() => {
        // Store the current path as the previous path whenever location changes
        // console.log(deviceType,"deviceType")
        return () => {
          localStorage.setItem("previousPath", location.pathname);
          setPreviousPath(location.pathname);
        };
      }, [location.pathname]);
    

  let dashboardLink;

 console.log(previousPath,"previousPath")

  if (loginType === "USER") {
    dashboardLink = "/dashboard";
  } else if (loginType === "ADMIN") {
    dashboardLink = "/adminDashboard";
  } else if (loginType === "SUPER_ADMIN") {
    dashboardLink = "/superAdminClientDashboard";
  }

  const generateText = (companyName, userFirstName, userUsername, pathname) => {
    let text = `Welcome, ${companyName.toUpperCase()}`;
    let additionalText = "";
  
    if (pathname.includes('/user-dashboard')) {
      additionalText = `. You are viewing ${userFirstName} ${userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}'s dashboard.`;
    } else if (pathname.includes('/myRides')) {
      additionalText = `. You are viewing ${userFirstName} ${userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}'s ride list.`;
    } else if (pathname.includes('/ridedetails')) {
      additionalText = `. You are viewing ${userFirstName} ${userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}'s ride details.`;
    }
  
    return text + additionalText;
  };
  
  const text = generateText(companyName, userFirstName, userUsername, location.pathname);
  const isLongText = text.length > 95;
  
  const dynamicFontSize = isLongText ? '12px' : '12px';

  const getRideListSA = async () => {

    console.log(loginType,"loginType")
    var typeChange = ((loginType == "ADMIN") || (loginType == "SUPER_ADMIN")) ? "DIVISION_PROFILE_IMAGE" : "USER_PROFILE_IMAGE";
    var result = await AdminDashboardCtrlr.getProfileLogo(
      deviceId,
      userId,     
    );

    console.log(result,"result")
    
    try {
      
      if(result.status == "SUCCESS") {
        const blob = new Blob([result.data], { type: 'image/jpeg' });  // Set correct mime type (e.g., 'image/jpeg', 'image/png')
        const url = URL.createObjectURL(blob);
        setGetImage(url);
      }     
     
    }catch (errMes) {
      console.log(errMes.message)
    }
  }

  const getAdminLoginUserProfile = async () => {
   
    var result = await AdminDashboardCtrlr.getAdminLoginUserProfile(
      deviceId,
      userId,     
    );    
    console.log(result,"result")
    try {
      
      if(result.status == "SUCCESS") {
        const blob = new Blob([result.data], { type: 'image/jpeg' });  // Set correct mime type (e.g., 'image/jpeg', 'image/png')
        const url = URL.createObjectURL(blob);
        setGetUserImage(url);
      }     
     
    }catch (errMes) {
      console.log(errMes.message)
    }
  }


  useEffect(() => {

    if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "")){
       getRideListSA();
    } 
      
  },[deviceId])

  useEffect(() => {
      
    if((userType === "ADMIN") && 
  (location.pathname.includes("/user-dashboard") ||
    location.pathname.includes("/myRides") ||
    location.pathname.includes("/ridedetails")) && profilePicturePathAdminToUser && (profilePicturePathAdminToUser != "undefined") && (profilePicturePathAdminToUser != "null") && (profilePicturePathAdminToUser != "") && (profilePicturePathAdminToUser != undefined)){
      
      getAdminLoginUserProfile();
    }
 
 
      
  },[userId])


  const VehicleDeviceDisplay = ({ vehicleType, deviceType }) => {
    const normalizedDeviceType = deviceType.toLowerCase(); // Normalize the deviceType to lowercase

    

    return (
      <div
        className="d-none d-lg-flex flex-column justify-content-center align-items-center px-2 py-1"
        style={{ width: "auto"
          // ,borderLeft:"1px solid #C3C3C3"
        }}
      >
         <div>
          {normalizedDeviceType === "ios" ? (
            <img src={iosIconNew} width={25} height={25} alt="iOS" />
          ) : normalizedDeviceType === "android" ? (
            <img src={andIconNew} width={25} height={25} alt="Android" />
          ) : (
            <h6 className="mb-0">-</h6>
          )}
        </div>

        <hr className="w-100 my-1" />

        <div>
          {vehicleType === "TWO_WHEELER" ? (
            <img src={TW} width={25} height={25} alt="Two Wheeler" />
          ) : vehicleType === "FOUR_WHEELER" ? (
            <img src={FW} width={25} height={25} alt="Four Wheeler" />
          ) : (
            <h6 className="mb-0">-</h6>
          )}
        </div>
       
       
      </div>
    );
  };

  


  function getProfileImage(loginType, getImage) {
   
    if( (loginType == "ADMIN")){ 
 
     if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "")){
      
       return getImage
     }else{
      
       return CompanyDefaultImage
     }
 
   }else if((loginType == "SUPER_ADMIN")){
 
     if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "")){
      
       return getImage
     }else{
      
       return CompanyDefaultImage
     }
 
   }else if((loginType == "USER")){
 
     if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "")){
      
       return getImage
     }else{
      
       return CompanyDefaultImage
     }
 
   }
   
 }


 function getProfileUserImage(loginType, getUserImage) {

  
  
  if( (loginType == "ADMIN")){ 
   
    if(profilePicturePathAdminToUser && (profilePicturePathAdminToUser != "undefined") && (profilePicturePathAdminToUser != "null") && (profilePicturePathAdminToUser != "")){
     
      return getUserImage
    }else{
     
      return UserLogo
    }

  }else if((loginType == "SUPER_ADMIN")){

    if(profilePicturePathAdminToUser && (profilePicturePathAdminToUser != "undefined") && (profilePicturePathAdminToUser != "null") && (profilePicturePathAdminToUser != "")){
     
      return getUserImage
    }else{
     
      return UserLogo
    }

  }
 
}


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header" style={{ padding: "0px" }}>
          <div className="d-flex">
            <div className="navbar-brand-box" style={{marginLeft:"7px"}}>
              <Link to={dashboardLink} className="logo logo-dark ms-2">
                <span className="logo-sm">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
                <span className="logo-lg">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
              </Link>

              <Link to={dashboardLink} className="logo logo-light">
                <span className="logo-sm">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
                <span className="logo-lg">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input type="text" className="form-control"
                  placeholder="Search..." />
                <button className="btn btn-primary" type="button"><i
                  className="bx bx-search-alt align-middle"></i></button>
              </div>
            </form> */}
            {/* <div className="d-none d-md-flex justify-content-center align-items-center" style={{maxWidth: '765px'}}>
    <h5 className="mb-0 mt-1">Drive Safe, Arrive Safe</h5>
</div> */}
          </div>

          

          <div className="d-flex ">
         
          <div className="border-start border-end" style={{textAlign:"center"}}>
            <div data-tooltip-id="DisplayNAmeZoneName" style={{display:"flex",flexDirection:"row"}}>
            <div className="px-1 mt-2" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
            <img
                src={getProfileImage(loginType, getImage)}
                alt="Profile"
                height="50px"
                style={{ cursor: 'pointer' }} // Change cursor to pointer on hover
              />
            </div>
            
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",marginTop:"5px"}} className="px-2">
            <span style={{ fontSize: dynamicFontSize, fontWeight: 800,}}>{companyName.toUpperCase()}</span>
            <span style={{ fontSize: dynamicFontSize, }}>{addressLine1 && `${addressLine1},`} {addressLine2}</span>
            <span style={{ fontSize: dynamicFontSize, }}>{city && `${city},`} {state && `${state},`} {country && `${country}.`} {postalCode && ` Pin: ${postalCode}`} {` Time Zone: ${abbreviation}`}</span>
            </div>
            
            </div>
           <ReactTooltip
                       id="DisplayNAmeZoneName"
                       place="bottom"
                       offset={5}
                       content={`${DisplayNAme}, ${ZoneName}`}
                       style={{ backgroundColor: "black" }}
                     />

          </div>

          {/* <TopBarTimeZoneDetails /> */}
          {loginType === "ADMIN" && (  <div>
            <div
        className="d-none d-lg-flex flex-column justify-content-center align-items-center px-2 py-1"
        style={{ width: "auto"
          // ,borderLeft:"1px solid #C3C3C3"
        }}
      >
         <div>
         <Link to="/mySubscription" >
            <img data-tooltip-id="WorkOrderIconTooltip" src={WorkOrderIcon} width={25} height={25} alt="Android" />
            </Link> 
            <ReactTooltip
                       id="WorkOrderIconTooltip"
                       place="bottom"
                       offset={5}
                       content="Your Work Orders"
                       style={{ backgroundColor: "black" }}
                     />
            
          
        </div>

        <hr className="w-100 my-1" />

        <div>
         
        <MyUsersNewTable/>
          
           
         
        </div>
       
       
      </div>
            </div>)}
         

          {loginType === "ADMIN" && (location.pathname.includes("/user-dashboard") ||
            location.pathname.includes("/myRides") ||
            location.pathname.includes("/ridedetails")) && 
           <>
          <div className="px-1" style={{display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid #C3C3C3"}} >
          <img
                src={getProfileUserImage(loginType, getUserImage)}
                alt="Profile"
                height="40px"
               
              />
          </div>
          
         
          
      <div className="px-2  border-end" style={{display:"flex",flexDirection:"column",justifyContent:"center"}} >
      {/* <span style={{ fontSize: dynamicFontSize, fontWeight: 600 }} className="text-primary">
                  Welcome,{" "}{companyName.toUpperCase()}
                  </span> */}
      {location.pathname.includes('/user-dashboard') && (
                    <>
                    {/* <span style={{float:"inline-start",backgroundColor:"red",width:"100%"}}>You are viewing</span><br/> */}
                    <span>
                      {/* &nbsp;You are viewing{" "} */}
                      <span style={{ fontSize: dynamicFontSize}}>                       
                        {userUsername && userUsername.length > 0 ? `User ID: ${userUsername}` : ""}
                      </span>
                      <br/>
                      <span style={{ fontSize: dynamicFontSize, fontWeight: 800 }}>
                        {userFirstName}{" "}                       
                      </span>
                     
                      <br/>
                      Dashboard
                    </span>
                    </>
                  )}

{location.pathname.includes("/myRides") && (
                    <>
                    {/* <span style={{float:"inline-end"}}>You are viewing</span><br/> */}
                    <span>
                      {/* &nbsp;You are viewing{" "} */}
                      <span style={{ fontSize: dynamicFontSize}}>                       
                        {userUsername && userUsername.length > 0 ? `User ID: ${userUsername}` : ""}
                      </span>
                      <br/>
                      <span style={{ fontSize: dynamicFontSize, fontWeight: 800 }}>
                        {userFirstName}{" "}                       
                      </span>
                     
                      <br/>
                      Ride List
                    </span>
                    </>
                  )}

{location.pathname.includes("/ridedetails") && (
                    <>
                    {/* <span style={{float:"inline-end"}}>You are viewing</span><br/> */}
                    <span>
                      {/* &nbsp;You are viewing{" "} */}
                      <span style={{ fontSize: dynamicFontSize}}>                       
                        {userUsername && userUsername.length > 0 ? `User ID: ${userUsername}` : ""}
                      </span>
                      <br/>
                      <span style={{ fontSize: dynamicFontSize, fontWeight: 800 }}>
                        {userFirstName}{" "}                       
                      </span>
                     
                      <br/>
                       Ride Detail
                    </span>
                    </>
                  )}
                  </div>
         
          </> }
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            {/* {(companyName !== undefined && companyName.length > 0  ) ? ( */}
           
              {/* <div className="d-none d-lg-flex align-items-center pe-3 border-right" >             
                 
                 {loginType === "ADMIN" && (  
                <span style={{ fontSize: dynamicFontSize, fontWeight: 600 }}>                
                  
                  
                 
                </span>
                  )}
              </div> */}
          

            {/* {loginType === "SUPER_ADMIN" && (
              <div className="d-none d-lg-flex align-items-center pe-3 border-right">
                <span style={{ fontSize: "14px", fontWeight: 600 }}>
                  Welcome,{" "}
                  <span
                    style={{ fontSize: "14px", fontWeight: 600 }}
                    className="text-primary"
                  >
                    {companyName.toUpperCase()}
                  </span>
                </span>
              </div>
            )} */}


            {/* {loginType === "USER" && (
              <div className="d-none d-lg-flex align-items-center pe-3">
                <span style={{ fontSize: "14px", fontWeight: 600 }}>
                  Welcome,{" "}
                  <span
                    style={{ fontSize: "14px", fontWeight: 600 }}
                    className="text-primary"
                  >
                    {userName.toUpperCase()}
                  </span>
                </span>
              </div>
            )} */}

           
           

            {/* light / dark mode */}
           

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              {/* <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu> 
            </Dropdown> */}
            
           
            {/* 
            <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  dispatch(showRightSidebarAction(!props.showRightSidebar));
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <FeatherIcon
                  icon="settings"
                  className="icon-lg"
                />

              </button>
            </div> */}

<div>
        {userType === "USER" ? (
          <VehicleDeviceDisplay
            vehicleType={vehicleType}
            deviceType={deviceType}
          />
        ) : userType === "ADMIN" &&
          (previousPath.includes("/adminDashboard") ||
            // previousPath.includes("/myusertabledata") ||
            previousPath.includes("/ridedetails") ||
            previousPath.includes("/user-dashboard") ||
            previousPath.includes("/myRides")) &&
          (location.pathname.includes("/user-dashboard") ||
            location.pathname.includes("/myRides") ||
            location.pathname.includes("/ridedetails")) ? (
          <VehicleDeviceDisplay
            vehicleType={vehicleType}
            deviceType={deviceType}
          />
        ) : null}
      </div>
         
            
             {/* {loginType === "ADMIN" && (
            <div className="d-none d-lg-flex align-items-center pe-1  px-1 py-1 " style={{fontSize:"12px",borderLeft:"1px solid #C3C3C3"}}>
            <Link to="/mySubscription" >
            <div style={{textAlign:"center"}}>Your</div>
            <div style={{textAlign:"center"}}>Work</div>
            <div style={{textAlign:"center"}}>Orders</div>
            </Link> 
            </div>)}
            {loginType === "ADMIN" && (
            <div className="d-none d-lg-flex align-items-center  border-start px-2 py-1 ">
                    <MyUsersNewTable/>
                    

                    </div>)} */}

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
