import { apiConfig } from "../config/Config.js";
import { SC_SECRET_KEY, SC_USER_ID, SC_USER_SESSION_ID } from "../constant/StorageConstant.js";
import { apiHdrDefValue, apiHttpStatus, errorMessage } from "../constant/ApiConstant.js";
import Converter from "../components/Common/EncodeDecode";
import axios from "axios";
import ToastService from "../components/Common/toastService.js";

export let ApiService = {
  jsonToQueryString(json) {
    return (
      "?" +
      Object.keys(json)
        .map(function (key) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        })
        .join("&")
    );
  },

  async callGET(apiVersion, apiResource, sessionType, reqParams) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqParams === "undefined" || reqParams == "") {
        reqParams = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqParams !== "object") {
        reqParams = {};
      }
      var queryParams = this.jsonToQueryString(reqParams);
      var apiFullEndPoint =
        apiConfig.API_ROOT_URL + apiVersion + apiResource + queryParams;
      // console.log("payload :"+ JSON.parse(JSON.stringify(apiFullEndPoint)))

      let resultAPI = await axios.get(apiFullEndPoint, {
        headers: {
          "content-type": apiHdrDefValue.FORM_URLENCODED,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });

      if (resultAPI.status == apiHttpStatus.SC_200) {
        console.log(resultAPI, "resultAPIuhuhuihuiresultAPI")
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "code" : 403, "data": "", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        // console.log("Dashboard Network Error:", err);
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        // console.log("Dashboard Client Error:", err);
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("GET service: ", result);
    return result;
  },

  async callImageGET(apiVersion, apiResource, sessionType, reqParams) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqParams === "undefined" || reqParams == "") {
        reqParams = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqParams !== "object") {
        reqParams = {};
      }
      var queryParams = this.jsonToQueryString(reqParams);
      var apiFullEndPoint =
        apiConfig.API_ROOT_URL + apiVersion + apiResource + queryParams;
      // console.log("payload :"+ JSON.parse(JSON.stringify(apiFullEndPoint)))

      let resultAPI = "";
      resultAPI = await axios.get(apiFullEndPoint, {
        responseType: 'arraybuffer',
        headers: {
          "content-type": apiHdrDefValue.FORM_URLENCODED,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });
      console.log(resultAPI, "resultAPIuhuhuihuiresultAPI")
      
      if (resultAPI.status == apiHttpStatus.SC_200) {
        result = { status: "SUCCESS", data: resultAPI.data };
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "code" : 403, "data": "", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        // console.log("Dashboard Network Error:", err);
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        // console.log("Dashboard Client Error:", err);
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("GET service: ", result);
    return result;
  },


  async encryptCallGET(apiVersion, apiResource, sessionType, reqParams) {
    var secrectKey = localStorage.getItem(SC_SECRET_KEY)
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqParams === "undefined" || reqParams == "") {
        reqParams = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqParams !== "object") {
        reqParams = {};
      }
      var queryParams = this.jsonToQueryString(reqParams);
      var apiFullEndPoint =
        apiConfig.API_ROOT_URL + apiVersion + apiResource + queryParams;
      // console.log("payload :"+ JSON.parse(JSON.stringify(apiFullEndPoint)))

      let resultAPI = await axios.get(apiFullEndPoint, {
        headers: {
          "content-type": apiHdrDefValue.FORM_URLENCODED,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });



      if (resultAPI.status == apiHttpStatus.SC_200) {
        const enCodeResult = resultAPI.data.message;

        let decode = Converter.decryptDataCBC(enCodeResult, secrectKey);

        // console.log("enCodeResult: " + enCodeResult + " decodeResult: " + decode)
        result = '{"status": "SUCCESS", "data": ' + decode + "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "code" : 403, "data": "", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        // console.log("Dashboard Network Error:", err);
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        // console.log("Dashboard Client Error:", err);
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("GET service: ", result);
    return result;
  },
  async callPOST(apiVersion, apiResource, sessionType, reqPayload) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }

      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }

      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqPayload === "undefined" || reqPayload == "") {
        reqPayload = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqPayload !== "object") {
        reqPayload = {};
      }

      var apiFullEndPoint = apiConfig.API_ROOT_URL + apiVersion + apiResource;

      let resultAPI = await axios.post(apiFullEndPoint, reqPayload, {
        headers: {
          "content-type": apiHdrDefValue.APPLICATION_JSON,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });


      if (resultAPI.status == apiHttpStatus.SC_201) {

        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode == apiHttpStatus.SC_440) {
          window.location.href = "/login";
          localStorage.removeItem(SC_USER_ID);
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode == apiHttpStatus.SC_404) {
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("POST service: ", result);
    return result;
  },
  async encryptCallPOST(apiVersion, apiResource, sessionType, reqPayload, formData) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }

      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }

      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqPayload === "undefined" || reqPayload == "") {
        reqPayload = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqPayload !== "object") {
        reqPayload = {};
      }

      var queryParams = this.jsonToQueryString(reqPayload);

      var apiFullEndPoint = apiConfig.API_ROOT_URL + apiVersion + apiResource + queryParams;

      let resultAPI = await axios.post(apiFullEndPoint, formData, {

        headers: {

          referredBy: apiHdrDefValue.REFERRED_BY,
          "content-type": apiHdrDefValue.MULTIPART_FORM_DATA,
          sessionId: sessionId,
        },
      });

      if (resultAPI.status == apiHttpStatus.SC_201) {
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
          ToastService.errormsg("Please check that whether you have used same username or invalid device type")
        } else if (eStatusCode == apiHttpStatus.SC_440) {
          window.location.href = "/login";
          localStorage.removeItem(SC_USER_ID);
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("POST service: ", result);
    return result;
  },
  async callPUT(apiVersion, apiResource, sessionType, reqPayload) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqPayload === "undefined" || reqPayload == "") {
        reqPayload = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqPayload !== "object") {
        reqPayload = {};
      }

      var apiFullEndPoint = apiConfig.API_ROOT_URL + apiVersion + apiResource;
      let resultAPI = await axios.put(apiFullEndPoint, reqPayload, {
        headers: {
          "content-type": apiHdrDefValue.APPLICATION_JSON,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });

      if (resultAPI.status == apiHttpStatus.SC_202) {
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("PUT service: ", result);
    return result;
  },
  async callDELETE(apiVersion, apiResource, sessionType, reqParams) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqParams === "undefined" || reqParams == "") {
        reqParams = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqParams !== "object") {
        reqParams = {};
      }

      var queryParams = this.jsonToQueryString(reqParams);
      var apiFullEndPoint =
        apiConfig.API_ROOT_URL + apiVersion + apiResource + queryParams;
      let resultAPI = await axios.delete(apiFullEndPoint, {
        headers: {
          "content-type": apiHdrDefValue.FORM_URLENCODED,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });

      if (resultAPI.status == apiHttpStatus.SC_202) {
        result = '{"status": "SUCCESS", "data": []}';
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        // console.log("Dashboard Network Error:", err);
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        // console.log("Dashboard Client Error:", err);
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("DELETE Service: sessionId: ", sessionId, " result: ", result);
    return result;
  },
  async callPATCH(apiVersion, apiResource, sessionType, reqPayload) {
    var sessionId = "";
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (apiResource === "undefined" || apiResource == "") {
        apiResource = "";
      }
      if (apiVersion === "undefined" || apiVersion == "") {
        apiVersion = apiConfig.API_VERSION_1;
      }
      if (reqPayload === "undefined" || reqPayload == "") {
        reqPayload = {};
      }
      if (
        sessionType === "undefined" ||
        sessionType == "" ||
        sessionType == "0"
      ) {
        sessionId = apiConfig.STATIC_SESSION;
      } else {
        try {
          sessionId = localStorage.getItem(SC_USER_SESSION_ID);
        } catch (err) {
          sessionId = "";
        }
      }

      if (typeof reqPayload !== "object") {
        reqPayload = {};
      }

      var apiFullEndPoint = apiConfig.API_ROOT_URL + apiVersion + apiResource;
      let resultAPI = await axios.patch(apiFullEndPoint, reqPayload, {
        headers: {
          "content-type": apiHdrDefValue.APPLICATION_JSON,
          referredBy: apiHdrDefValue.REFERRED_BY,
          sessionId: sessionId,
        },
      });

      if (resultAPI.status == apiHttpStatus.SC_202) {
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode == apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        result = '{"status": "FAILED", "error" : "Network Error"}';
        ToastService.errormsg(errorMessage.ELSE_ERROR);
      } else {
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("PATCH service: ", result);
    return result;
  },
};

export default ApiService;