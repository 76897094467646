import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,  
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import {  CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from '@mui/material/Tooltip';
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg";
import "jspdf-autotable";
import { Link, useNavigate } from "react-router-dom";
import ValueFormat from "../../util/ValueFormat.js";
import {
  RIDEDETAILS,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import Loader from "../../components/Common/loader";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import { displayText } from "../../constant/MessageConstant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Converter from "../../components/Common/EncodeDecode.js";
import ScreenCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import AccelerationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import DetailViewd from "../../assets/images/adminDashboard/myRidesPageIcons/journeyViewIconNew.svg";
import UBIAnticipation from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import UBISelfConfidence from "../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg";
import UBIDrivingSkill from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg";
import UBIDrivingStyle from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg";
import UBIDrivingState from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg";
import UBIMobileCallUse from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
import OverSpeedIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import UBIStressStrain from "../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg";
import battery from "../../assets/images/adminDashboard/myRidesPageIcons/chargerIcon.svg";
import clock from "../../assets/images/adminDashboard/myRidesPageIcons/clockIcon.svg";
import CallCountIconNewOne from "../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import CountImage from "../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import StartLocationIcon from "../../assets/images/common/startLocationImage.svg";
import EndLocationIcon from "../../assets/images/common/endLocationImage.svg";
import steeringWheel from "../../assets/images/adminDashboard/steering-wheel.svg";
import locationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import minsIcon from "../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import "../../pages/MyRides/MyRidesMobScreen.css";
import MyRidesCtrlr from "../../controller/RideDashboardCtrlr.js";
import UtilDateTime from "../../util/UtilDateTime.js";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf.js";
import { Select ,MenuItem} from "@mui/material";

const TableStyle = {
  table: {
    width: "100%",
    display: "table",
    borderSpacing: 0,
    border: "0.2px",
    margin: "0px",
  },
  th: {
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    height: "0px",
    fontWeight: 600,
    fontSize: "12px",
  },
};

function NewRideList() {
 
  const [workFilter, setWorkFilter] = useState("");  
  const [loginType, setLoginType] = useState("");
  const [filter, setFilter] = useState("CURRENT_YEAR"); 
  const [pageNumber, setPageNumber] = useState(1);
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [apiData, setApiData] = useState([]);
  const [indexStart, setIndexStart] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [dataLength, setDataLength] = useState(null); 
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [count, setCount] = useState(0);
  
  const inputRef = useRef();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);  
  const [itemPerPage, setItemPerPage] = useState(5);

  const pageSize = itemPerPage;
 
  
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); 
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const newRideListGet = async (indexStartOfSet,searchClear) => {

    try {

      setShowLoader(true);

      var searchData = (searchClear == "CLEAR") ? "" : searchText;      
       
      

      var result = await MyRidesCtrlr.getV1RideList(
        indexStartOfSet,
        filter,
        workFilter,       
        searchData,
        pageSize,
        startDateTime,
        endDateTime,
        itemPerPage
      );

      setShowLoader(false);

      var resultJSON = JSON.parse(result);
     
      var status = resultJSON.status;

      var updatedTableData = [];

      if (status == "SUCCESS") {

        var resultJSONData = resultJSON.data;

        console.log("Ride List Data", resultJSONData);

        setDataLength(resultJSONData.length);
        
        for (let i = 0; i < resultJSONData.length; i++) {

          var userRideDetail              = resultJSONData[i];
          var status                      = ValueFormat.parseAsString(userRideDetail.status);
          var insertMode                  = ValueFormat.parseAsString(userRideDetail.insertMode) || "-";
          var rideName                    = ValueFormat.parseAsString(userRideDetail.deviceName);
          var drivingCategory             = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.calculatedDrivingCategory));
          var deviceMode                  = ValueFormat.parseAsString(userRideDetail.deviceMode);
          var riskSlot10count             = ValueFormat.parseAsInt(userRideDetail.riskSlot10count);
          var riskSlot10Distance          = ValueFormat.parseAsFloat(userRideDetail.riskSlot10DistanceInKm,2);

          
          var riskSlot10Duration          = ValueFormat.parseAsFloat(userRideDetail.riskSlot10DurationInMin,2);
          var calculatedDrivingScore      = ValueFormat.parseAsFloat(userRideDetail.calculatedDrivingScore,0) || 0;
          var calculatedDrivingScoreValue = ValueFormat.parseAsString(userRideDetail.calculatedDrivingScore) || 0;
          var userId                      = ValueFormat.parseAsString(userRideDetail.userId);
          var overallDrivingScore         = ValueFormat.parseAsFloat(userRideDetail.drivingScore, 0) || 0;
          var overallAnticipation         = ValueFormat.parseAsFloat(userRideDetail.anticipationScore, 0) || 0;
          var overallSelfConfidence       = ValueFormat.parseAsFloat(userRideDetail.selfConfidenceScore, 0) || 0;
          var overallDrivingSkill         = ValueFormat.parseAsFloat(userRideDetail.drivingSkillScore, 0) || 0;
          var dStyleValue                 = ValueFormat.parseAsFloat(userRideDetail.drivingStyleScore, 0) || 0;
          var dStateValue                 = ValueFormat.parseAsFloat(userRideDetail.drivingStateScore, 0) || 0;
          var kmsDriven                   = ValueFormat.parseAsFloat(userRideDetail.overspeedScore, 0) || 0;             
          var mobileUsage                 = parseFloat(userRideDetail.mobileCallScore) +  parseFloat(userRideDetail.mobileScreenScore);             
          var abcPoint                    = ValueFormat.parseAsFloat(userRideDetail.accBrakCorScore, 0) || 0;
          var cpp                         = ValueFormat.parseAsFloat(userRideDetail.crashProbabilityScore, 0) || 0;
          var totalAccelerationCount      = parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeAccelerationLowCount,0)) 
                                            +
                                            parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeAccelerationMediumCount,0)) 
                                            +
                                            parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeAccelerationHighCount,0));
          var totalBrakingCount           = parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeBrakingLowCount, 0)) 
                                            +
                                            parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeBrakingMediumCount,0))
                                            +
                                            parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeBrakingHighCount, 0));
          var totalCorneringCount         = parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeCorneringLowCount,0))
                                            +
                                            parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeCorneringMediumCount,0))
                                            +
                                            parseFloat(ValueFormat.parseAsFloat(userRideDetail.severeCorneringHighCount,0));


          var totalMobileUseInAcceptedCount     = ValueFormat.parseAsFloat(userRideDetail.mobileCallCount,0) || "-";
          const parsedNum                       = parseFloat(userRideDetail.mobileCallDurationInMin);
          var totalMobileUseInAcceptedDuration  = Number.isInteger(parsedNum) ? parsedNum : ValueFormat.parseAsFloat(userRideDetail.mobileCallDurationInMin,2) || "-";
          var totalMobileScreenScreenOnCount    = ValueFormat.parseAsFloat(userRideDetail.mobileScreenCount,0) || "-";
          const parsedNumMobileScreen           = parseFloat(userRideDetail.mobileScreenDurationInMin);
          var totalMobileScreenScreenOnDuration = Number.isInteger(parsedNumMobileScreen) ? parsedNumMobileScreen : ValueFormat.parseAsFloat(userRideDetail.mobileScreenDurationInMin,2) || "-";          
          var totalOverSpeedCount               = ValueFormat.parseAsFloat(userRideDetail.overSpeedCount, 0) || "-";
          const parsedNumOverSpeed              = parseFloat(userRideDetail.overSpeedDurationInMin);
          var totalOverSpeedDuration            = Number.isInteger(parsedNumOverSpeed) ? parsedNumOverSpeed : ValueFormat.parseAsFloat(userRideDetail.overSpeedDurationInMin, 2) || "-";         
          var tKiloMeter                        = ValueFormat.parseAsFloat(userRideDetail.distanceInKm, 2) || "-";         
          var tTravelTime                       = ValueFormat.parseAsFloat(userRideDetail.durationInMin, 2) || "-";      
          var rideId                            = ValueFormat.parseAsString(userRideDetail.deviceId);         
          var divisionId                        = ValueFormat.parseAsString(userRideDetail.divisionId);
          var rideCategory                      = ValueFormat.parseAsString(userRideDetail.category);
          var startTime                         = ValueFormat.parseAsString(userRideDetail.startDateTimeToTimeZone) || "-";
          var endTime                           = ValueFormat.parseAsString(userRideDetail.endDateTimeToTimeZone) || "-";
          var batterylevelStart                 = ValueFormat.parseAsFloat(userRideDetail?.batteryLevelStart, 0) || "-";
          var batterylevelEnd                   = ValueFormat.parseAsFloat(userRideDetail?.batteryLevelEnd, 0) || "-";
          var longEvent                         = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.subType));
          var startLocationName                 = ValueFormat.parseAsString(userRideDetail.locationName) || "-";
          var startDeviceField                  = ValueFormat.parseAsObject(userRideDetail.deviceField);
          var startDeviceCity                   = ValueFormat.parseAsString(startDeviceField.city) || "-"; 
          var startDeviceState                  = ValueFormat.parseAsString(startDeviceField.state) || "-"; 
          var lastLocationName                  = ValueFormat.parseAsString(userRideDetail.lastLocationName) || "-";          
          var lastDeviceField                   = ValueFormat.parseAsObject(userRideDetail.lastDeviceField); 
          var lastDeviceCity                    = ValueFormat.parseAsString(lastDeviceField.city) || "-";
          var lastDeviceState                   = ValueFormat.parseAsString(lastDeviceField.state) || "-";
          var event                             = ValueFormat.parseAsString(userRideDetail.subType) || "";

          var shortEvent = "";
          if (event === "BACKGROUND_AUTO") {
            shortEvent = "BGA";
          } else if (event === "BACKGROUND_MANUAL") {
            shortEvent = "BGM";
          } else if (event === "FOREGROUND_AUTO") {
            shortEvent = "FGA";
          } else if (event === "FOREGROUND_MANUAL") {
            shortEvent = "FGM";
          } else {
            shortEvent = "-";
          }

          var totalCount                        = ValueFormat.parseAsInt(userRideDetail.totalCount);

          setTotalPageCount(Math.ceil(totalCount / pageSize));
          setNextShow(totalCount - indexStartOfSet > pageSize);

          var drivingBehaviourScore             = ValueFormat.parseAsFloat(userRideDetail.calculatedBehaviourScore) || "-";         
          var dayPercentage                     = ValueFormat.parseAsFloat(userRideDetail.dayPercentage, 0);
          var nightPercentage                   = ValueFormat.parseAsFloat(userRideDetail.nightPercentage, 0);          
          var urbanKilometer                    = ValueFormat.parseAsFloat(userRideDetail.urbanDistanceInKm, 2);
          var ruralKilometer                    = ValueFormat.parseAsFloat(userRideDetail.ruralDistanceInKm, 2);          
          var highwayKilometer                  = ValueFormat.parseAsFloat(userRideDetail.highwayDistanceInKm, 2);

          if(riskSlot10count > 0){
            if((riskSlot10Duration ==  0.00) || (riskSlot10Duration <  0.1)){
              riskSlot10Duration = "<0.1";
            }
          }

          if(riskSlot10count > 0){
            if((riskSlot10Distance ==  0.00) || (riskSlot10Distance <  0.1)){
              riskSlot10Distance = "<0.1";
            }
          }
        
          var resultObject = {

            rideName                          : rideName,
            status                            : status,
            drivingCategory                   : drivingCategory,
            drivingBehaviourScore             : drivingBehaviourScore,
            calculatedDrivingScore            : calculatedDrivingScore,
            calculatedDrivingScoreValue       : calculatedDrivingScoreValue,                   
            drivingScore                      : overallDrivingScore,
            rideId                            : rideId,
            userId                            : userId,
            divisionId                        : divisionId,                     
            ABCPoint                          : abcPoint,                       
            overallAnticipation               : overallAnticipation,
            overallSelfConfidence             : overallSelfConfidence,
            overallDrivingSkill               : overallDrivingSkill,
            totalKmSPoint                     : kmsDriven,
            DSP                               : dStateValue,
            DSTP                              : dStyleValue,                
            totalAccelerationCount            : totalAccelerationCount,
            totalBrakingCount                 : totalBrakingCount,
            totalCorneringCount               : totalCorneringCount,
            totalOverSpeedDuration            : totalOverSpeedDuration,
            totalMobileUseInAcceptedCount     : totalMobileUseInAcceptedCount,           
            totalMobileUseInAcceptedDuration  : totalMobileUseInAcceptedDuration,
            totalMobileScreenScreenOnCount    : totalMobileScreenScreenOnCount,           
            totalMobileScreenScreenOnDuration : totalMobileScreenScreenOnDuration,
            totalOverSpeedCount               : totalOverSpeedCount,           
            tKiloMeter                        : tKiloMeter,
            tTravelTime                       : tTravelTime,
            rideCategory                      : rideCategory,
            startTime                         : startTime,
            startTimeValue                    : startTime,
            startDateFormate                  : startTime.split(" ")[2],
            endTime                           : endTime,
            endDateFormate                    : endTime.split(" ")[2],
            batterylevelStart                 : batterylevelStart,
            batterylevelEnd                   : batterylevelEnd,
            batterylevelTotalValue            : (batterylevelEnd - batterylevelStart),
            event                             : shortEvent,
            longEvent                         : longEvent,                
            cppLevel                          : cppFunction(cpp).value,
            cppLevelColor                     : cppFunction(cpp).colorCode,           
            mobileUsage                       : ValueFormat.parseAsFloat(mobileUsage, 0),           
            day                               : dayPercentage,
            night                             : nightPercentage,           
            urbanKilometers                   : urbanKilometer,
            ruralKilometers                   : ruralKilometer,
            highwayKilometers                 : highwayKilometer,
            riskSlot10count                   : riskSlot10count,
            riskSlot10Distance                : riskSlot10Distance,
            riskSlot10Duration                : riskSlot10Duration,
            deviceMode                        : deviceMode,
            startLocationName                 : startLocationName,
            startDeviceCity                   : startDeviceCity,
            startDeviceState                  : startDeviceState,
            lastLocationName                  : lastLocationName,
            lastDeviceCity                    : lastDeviceCity,
            lastDeviceState                   : lastDeviceState,
            insertMode                        : insertMode,
            insertModeTooltip                 : insertMode == "RIDE_CSV" ? "Local Storage Ride" : insertMode == "API_CALL" ? "Cloud Storage Ride" : "-"

          };
          // console.log(resultObject,"resultObject")

          updatedTableData.push(resultObject);
        }

        setApiData(updatedTableData);
       
      }
    } catch (e) {

      // console.log("Cath Error While Getting Ride List: ", e);

    }

    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }

  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault(); 
      inputRef.current.click(); 
    }
  };


  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [filter, workFilter, startDateTime, endDateTime]);


  useEffect(() => {
    if (count > 0) newRideListGet(indexStart,"");
  }, [indexStart, count,itemPerPage]);


  const handleSearch = (e) => {
    e.preventDefault();
    newRideListGet(0,"");
    setPageNumber((current) => current - current + 1);
  };

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);


  const cppFunction = (data) => {
    let value = "";
    let colorCode = "";
    let levelDescription = "";

    if (data > 0 && data <= 50) {
      value     = "L1";
      colorCode = "#ff9b9b";
      levelDescription = "Range (1-50)";
    } else if (data > 50 && data <= 100) {
      value     = "L2";
      colorCode = "#ff8585";
      levelDescription = "Range (50-100)";
    } else if (data > 100 && data <= 150) {
      value     = "L3";
      colorCode = "#ff6e6e";
      levelDescription = "Range (100-150)";
    } else if (data > 150 && data <= 200) {
      value     = "L4";
      colorCode = "#ff5757";
      levelDescription = "Range (150-200)";
    } else if (data > 200 && data <= 250) {
      value     = "L5";
      colorCode = "#ff4040";
      levelDescription = "Range (200-250)";
    } else if (data == 0) {
      value     = "L0";
      colorCode = "#7ABA78";
      levelDescription = "Range (0)";
    } else if (data > 250) {
      value     = "L6";
      colorCode = "#ff2929";
      levelDescription = "Range (>250)";
    } else {
      value     = "-";
      colorCode = "#ffff";
      levelDescription = "No point available";
    }

    return { value, colorCode, levelDescription };
  };

  const deviceNameHandle = (deviceName) => {

    if(deviceName == "PERSONAL_AUTO"){
      return "Personal"
    }else if(deviceName == "WORK_AUTO"){
      return "Work"
    }else{
      return "-"
    }
  }

  const deviceNameHandleApi = (deviceName) => {

    if(deviceName == "RIDE_CSV"){
      return "LSR"
    }else if(deviceName == "API_CALL"){
      return "CSR"      
    }else{
      return "-"
    }
  }

  const deviceStatusHandle = (status) => {

    if(status == "ABNORMAL"){
      return "Abnormal"
    }else{
      return "-"
    }
  }

  const clearSearchInput = () => {
       
    setSearchText("");      
    newRideListGet(0,"CLEAR");     
    setPageNumber(1); 
      
  }

  const handleItemPerPageChange = (event) => {
    setItemPerPage(event.target.value)
  }

  // --------------------------------------------------------------------//

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Rides" breadcrumbItem="Rides" />
        <Row className="mt-2">
          <Col className="col-12 mt-2">
          <CardHeader className="mb-1" style={{ padding: "0px",border:"0px"}}>
                <Row style={{display:"flex", justifyContent:"space-between"}}>
                <Col xl={2} lg={2} md={2} style={{paddingRight: 0, }} >                   
                    <div  style={{ display:"flex",height:"100%"}}>
                      <div className="sub_heading_new" style={{display:"flex",justifyContent:"center",flexDirection:"column",marginLeft:"5px"}}>
                        Driving Score
                      </div>
                    </div>
                </Col>
                  {/* <Col xl={2} lg={2} md={2} style={{ paddingRight: 0, }} >                 
                    
                  </Col> */}
                  <Col xl={2} lg={2} md={3} style={{ padding: 0, }} >   
                    <div  style={{ textAlign: "right",marginTop:"9px"}}>
<Tooltip title="All">
                      <button
                        type="button"
                       
                        style={{fontWeight:700}}
                        className={
                          workFilter == ""
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("")}
                      >
                        All
                       
                      </button>
                      </Tooltip>
                      <Tooltip title="Work">
                      <button
                        type="button"
                       
                        style={{fontWeight:700}}
                        className={
                          workFilter == "WORK"
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("WORK")}
                      >
                        Work
                       
                      </button>
                      </Tooltip>
                      {" "}
                      <Tooltip title="Personal">
                      <button
                        type="button"
                       
                        style={{fontWeight:700}}
                        className={
                          workFilter == "PERSONAL"
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("PERSONAL")}
                      >
                        Personal
                       
                      </button>
                      </Tooltip>
                      {" "}
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} style={{  padding: 0 }} >
                    <div className="ms-auto ">
                     
                      <div
                        
                        style={{marginTop:"9px",textAlign:"center" }}
                      >
                        <Tooltip title="All">
                        <button
                          type="button"
                          style={{fontWeight:700}}
                          className={
                            filter == ""
                              ? "btn btnColorStylesNew btn-sm me-1"
                              : "btn btn-soft-secondary btn-sm me-1"
                          }
                          onClick={() => setFilter("")}
                          
                        >
                          All
                         
                        </button>
                        </Tooltip>
                        <Tooltip title="Current Day">
                        <button
                          type="button"
                          style={{fontWeight:700}}
                          className={
                            filter == "CURRENT_DAY" && !modalIsOpen
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_DAY")}
                         
                        >
                          CD
                          
                        </button>
                        </Tooltip>
                        {" "}
                        <Tooltip title="Current Week">
                        <button
                          type="button"
                          style={{fontWeight:700}}
                          className={
                            filter == "CURRENT_WEEK" && !modalIsOpen
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_WEEK")}
                         
                        >
                          CW
                         
                        </button>
                        </Tooltip>
                        {" "}
                        <Tooltip title="Current Month">
                        <button
                          type="button"
                          style={{fontWeight:700}}
                          className={
                            filter == "CURRENT_MONTH" && !modalIsOpen
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_MONTH")}
                         
                        >
                          CM
                         
                        </button>
                        </Tooltip>
                        {" "}
                        <Tooltip title="Current Year">
                        <button
                          type="button"
                          style={{fontWeight:700}}
                          className={
                            filter == "CURRENT_YEAR" && !modalIsOpen
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_YEAR")}
                         
                        >
                          CY
                         
                        </button>
                        </Tooltip>
                        {" "}
                        <Tooltip title="Custom Date Selection">
                        <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION" || modalIsOpen
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btnColorStylesSecondaryNew btn-sm"
                          }
                          onClick={() => {
                            setIsOpen(true);
                          }}
                         
                          style={{verticalAlign:"middle"}}
                        >
                          { filter == "CUSTOM_DURATION" || modalIsOpen ? <img src={filterClockIcon} alt="clock" style={{height:"18px",display:"flex",justifyContent:"center",flexDirection:"column"}}/> : <img src={filterClockIconDe} alt="clock" style={{height:"18px",display:"flex",justifyContent:"center",flexDirection:"column"}}/>}
                        
                        </button>
                        </Tooltip>
                        {" "}
                      </div>
                    </div>

                   
                  </Col>

                  <Col xl={4} lg={4} md={3} style={{paddingLeft: 0, }} >
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                    
                    <div
                      className="my-2"
                      style={{
                        height: "30px",
                        display: "flex",
                        justifyContent:"flex-end",
                        // marginLeft: "15px",
                        width:"53%"
                       
                      }}
                    >
                      <div 
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width:"100%",
                        position: "relative", // Add relative positioning to the wrapper
                      }}
                      >
                      <input
                        
                        className="specific-input"
                        style={{ border: " 1px solid #EEEBEB", width: "100%",height:"100%"}}
                        value={searchText}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        placeholder="Ride number or name"
                      />
                      {searchText &&
  <span
    style={{
      position: "absolute",
      right: "10px", 
      top: "50%",
      transform: "translateY(-50%)", 
      cursor: "pointer",
      color: "#888", 
    }}
    onClick={clearSearchInput} 
  >
    X
  </span>}
                      </div>
                      <div style={{display:"flex",justifyContent:"center",flexDirection:"column",}}>
                      <button
                        ref={inputRef}
                        style={{ marginLeft: "5px",display:"flex",justifyContent:"center",flexDirection:"column"  }}
                        className="btn btnColorStylesNew me-3"
                        // type="button"
                        onClick={handleSearch}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                      </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",}}>
                     <ViewPdf
                           apiDataRideList={apiData}              
                          
                                                                
                                            />
                                            </div>
                                            </div>
                  </Col>
                  
                </Row>
              </CardHeader>
          
              <CardBody className="p-0"  style={{ padding: "0px",marginTop:"-10px"}}>               
                    <div
                      className="mb-4 mt-3"    
                      style={{overflowX: "auto",border:"1px solid #DFDEDE"}}                  
                    >
                      
                      <Table bordered style={TableStyle.table}>
                        <thead>
                         
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Ride<br/>Details
                            
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/> Score
                            
                            </th>
                          
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Risk
                             
                            </th>

                          
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Risk
                              <br />
                              Data
                              
                            </th>
                         
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving <br />
                              Behaviour
                           
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Behaviour <br />
                              Data
                            
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br />
                              Probability
                            
                            </th>
                           
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acc/Brak/Cor
                              <br />
                              Counts
                            
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Over Speed
                              <br />
                              Data
                            
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile
                              <br />
                              Usage
                          
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              High Risk<br />
                              Data
                             
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Others
                            
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Context
                           
                            </th>
                          </tr>
                        </thead>
                        {dataLength > 0 && (
                          <tbody>
                            {(apiData || []).map((apiData, index) => (
                              <tr key={index}>
                                <td
                                 
                                  style={{
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    width:"250px",
                                  }}
                                >
                                  <div>
                                 <div
                                    style={{
                                      display: "flex",
                                    
                                    }}
                                  >
                                  <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                     
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        // flexDirection: "column",
                                        // marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      <div>
                                  <Link
                                    to={`/ridedetails?userId=${encodeURIComponent(
                                      Converter.encrypt(apiData.userId)
                                    )}&divisionId=${encodeURIComponent(
                                      Converter.encrypt(apiData.divisionId)
                                    )}&rideId=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideId)
                                    )}&rideCategory=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideCategory)
                                    )}`}
                                  >
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer",
                                        whiteSpace:"nowrap"
                                        
                                      }}
                                    >{`${apiData.rideName}`}</p>
                                  </Link>
                                  </div>
                                  <div style={{display:"flex"}}>
                          <div>
                          <button
                            type="button"
                            className="btn btn-sm "
                            style={{
                              backgroundColor: "#D7EC23",
                              padding: "3px",
                              fontSize: "8px",
                              fontWeight: 600,
                              marginLeft:"5px",
                              marginTop:"-3px"
                            }}
                          >
                           {deviceNameHandle(apiData.deviceMode)}
                          </button>
                          </div>
                          <p style={{marginLeft:"4px"}}>|</p>

                          
                           <div>
                          <Tooltip title={apiData.insertModeTooltip}><button
                            type="button"
                            className="btn btn-sm "
                            style={{
                              backgroundColor: "#D7EC23",
                              padding: "3px",
                              fontSize: "8px",
                              fontWeight: 600,
                              marginLeft:"5px",
                              marginTop:"-3px"
                            }}
                          >
                           {deviceNameHandleApi(apiData.insertMode)}
                          </button></Tooltip>
                          </div>
                        </div>
                                  </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                   
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        // marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {UtilDateTime.formatDateInt(apiData.startTimeValue)} | {formatTime(apiData.startTime)}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tKiloMeter + " Kms"} | 

                                      { 
                                        parseFloat(apiData.tTravelTime) < 60 
                                          ? ` ${parseFloat(apiData.tTravelTime).toFixed(2)} Mins` 
                                          : ` ${(parseFloat(apiData.tTravelTime) / 60).toFixed(2)} Hours` 
                                      }

                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={StartLocationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>                                    
                                   
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                        textAlign:"justify"
                                      }}
                                    >
                                      {apiData.startLocationName} {apiData.startDeviceCity} {apiData.startDeviceState}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={EndLocationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>                                    
                                   
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                        textAlign:"justify"
                                      }}
                                    >
                                      {apiData.lastLocationName} {apiData.lastDeviceCity} {apiData.lastDeviceState}
                                    </div>
                                  </div>                                          
                                  
                                  
                                  
                                  </div>

                                  
                                
                                </td>
                                
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                
                                >
                                {(apiData.status == "ABNORMAL") &&  <p
                           
                            style={{
                              
                              color:"red",
                              padding: "3px",
                              fontSize: "14px",
                              fontWeight: 700,
                              marginLeft:"5px",
                              marginTop:"-3px"
                            }}
                          >
                           {deviceStatusHandle(apiData.status)}
                          </p> }
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop:"10px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:
                                            apiData.calculatedDrivingScoreValue >=
                                              0 &&
                                            apiData.calculatedDrivingScoreValue <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScoreValue >
                                                  80 &&
                                                apiData.calculatedDrivingScoreValue <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                  
                                    
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                     justifyContent:"center",
                                     marginTop:"20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",                                        
                                        fontSize: "11px",
                                        marginLeft:"5px"
                                        
                                      }}
                                    >
                                      {apiData.drivingCategory}
                                    </div>
                                  </div>
                                </td>
                              
                                <td
                                  className="text-nowrap"
                                  data-tooltip-id={`categoryTooltip_${index}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}`,
                                  }} 
                                />
                              
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIAnticipation}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Anticipation
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBISelfConfidence}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "7px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Self Confidence
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingSkill}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Skill
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                    </div>
                                  </div>
                                </td>
                              
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <div style={{fontSize: "13px"}}>{`${apiData.drivingBehaviourScore}`}</div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingStyle}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Style
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving State
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIMobileCallUse}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "9px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Mobile Usage
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.mobileUsage}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Over Speed
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIStressStrain}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc/Brak/Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.cppLevel}
                                        text={`${apiData.cppLevel}`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:apiData.cppLevelColor,                                          
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    
                                   
                                  </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                      <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"20px",
                                        marginBottom:"0px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L0 is Safe</p>

                                    </div>
                                    
                                      
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L6 is Unsafe</p>

                                    </div>
                                    </div>
                                  
                                </td>
                                
                               
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={AccelerationIcon}
                                          alt="totalAccelerationCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalAccelerationCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={BreakingIcon}
                                          alt="totalBrakingCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Brak
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalBrakingCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={CorneringIcon}
                                          alt="totalCorneringCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalCorneringCount}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                     
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      
                                      {apiData.totalOverSpeedCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      { 
                                        parseFloat(apiData.totalOverSpeedDuration) < 60 
                                          ? `${parseFloat(apiData.totalOverSpeedDuration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.totalOverSpeedDuration) / 60).toFixed(2)} Hours` 
                                      }
                                    </div>
                                  </div>
                                 
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                    
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      { 
                                        parseFloat(apiData.totalMobileScreenScreenOnDuration) < 60 
                                          ? `${parseFloat(apiData.totalMobileScreenScreenOnDuration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.totalMobileScreenScreenOnDuration) / 60).toFixed(2)} Hours` 
                                      }
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIconNewOne}
                                        alt="icons2"
                                        style={{ height: "26px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      { 
                                        parseFloat(apiData.totalMobileUseInAcceptedDuration) < 60 
                                          ? `${parseFloat(apiData.totalMobileUseInAcceptedDuration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.totalMobileUseInAcceptedDuration) / 60).toFixed(2)} Hours` 
                                      }
                                    </div>
                                  </div>                           
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.riskSlot10count}{" "}{"Count"}
                                    </div>
                                  </div>           

                                  
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.riskSlot10Distance}{" "}{"Kms"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {/* { 
                                        parseFloat(apiData.riskSlot10Duration) < 60 
                                          ? `${parseFloat(apiData.riskSlot10Duration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.riskSlot10Duration) / 60).toFixed(2)} Hours` 
                                      } */}

                                     {apiData.riskSlot10Duration}{" "}{"Mins"}
                                    </div>
                                  </div>
                                 
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{display:"flex",marginBottom:"0px"}}>
                                    <div>
                                    <Tooltip title={
                                      <>
                                      <div>Start Time: {`${UtilDateTime.formatDateTimeNew(apiData.startTime)} ${apiData.startDateFormate}`}</div>
                                      <div>End Time: {`${UtilDateTime.formatDateTimeNew(apiData.endTime)} ${apiData.endDateFormate}`}</div>
                                     </>}>
                                  <img                             
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                  />
                                  </Tooltip>
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>RD</div>
                                          </div>
                                 <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                 <div>
                                 <Tooltip title={apiData.batterylevelTotalValue < 0 ? `Battery Consumption | ${Math.abs(apiData.batterylevelTotalValue)}% ` : apiData.batterylevelTotalValue == 0 ? "No Battery Consumption" : "Battery Charged" }>
                                  <img                              
                                    src={battery}
                                    alt="icons1"
                                    style={{                                     
                                      height: "25px",
                                    }}
                                  />
                                  </Tooltip>
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>BC</div>
                                  </div>
                                  <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                    <div>
                                  <Link
                                    to="/journeyView"
                                    state={{
                                      userId: apiData.userId,
                                      divisionId: apiData.divisionId,
                                      rideId: apiData.rideId,
                                      rideNo: apiData.rideNo,
                                      totalKms:apiData.tKiloMeter
                                    }}
                                    className="m-0 p-0"
                                  >
                                  <img
                                    src={DetailViewd}
                                    alt="icons1"
                                    style={{
                                      // marginTop: "15px",
                                      height: "25px",
                                      cursor:"pointer"
                                    }}
                                  />
                                  </Link>
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px",marginLeft:"2px"}}>JV</div>
                                  </div>                                    
                               
                                 <div>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {apiData.event}
                                        </p>

                                 </div>
                                 
                                  
                                 
                                 
                                  <ReactTooltip
                                    id={`event_${index}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.longEvent}
                                  />
                                 
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 
                                  <div style={{display:"flex"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            fontWeight:700
                                            // marginTop: "10px",
                                          }}
                                        >
                                          U{" - "}
                                        </p>
                                        
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.urbanKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            // marginTop: "10px",
                                            fontWeight:700
                                          }}
                                        >
                                          R{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px",
                                            whiteSpace:"nowrap"
                                           
                                          }}
                                        >
                                        {apiData.ruralKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700,
                                            whiteSpace:"nowrap"
                                          }}
                                        >
                                          H{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px",
                                             whiteSpace:"nowrap"
                                           
                                          }}
                                        >
                                        {apiData.highwayKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>

                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          D{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px",
                                             whiteSpace:"nowrap"
                                           
                                          }}
                                        >
                                        {apiData.day}{" "}{"%"}
                                        </p>

                                 </div>         
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 
                                  <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700,
                                          }}
                                        >
                                          N{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px",
                                             whiteSpace:"nowrap"
                                           
                                          }}
                                        >
                                        {apiData.night}{" "}{"%"}
                                        </p>

                                 </div>                                  
                                 
                                  
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>

                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )}
                    </div>
                
              </CardBody>
            
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <div>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              </div>

              <div>
              {dataLength > 0 ? (
               
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Page
                    </p>
                    <div className="pagination ">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                    
                  </div>
                ) : null}
                  
               
              
              </div> 

              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Items per page
                    </p>
              <Select
            size="small"
            id="items-per-page"
            value={itemPerPage}
            onChange={handleItemPerPageChange}
            sx={{
              marginLeft: "10px",
              marginRight: "20px",
              fontSize: "12px",
              fontWeight: 200,
              color: "#000",
              border: "0px solid #606060",
              width: "76px",
              height: "41px",
              borderRadius: "5px",
              // border: "1px solid rgba(255, 189, 128, 1)",
              boxShadow: "none",
              backgroundColor:"#74788D1A",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
            }}
          >
            <MenuItem value={5} sx={{fontSize:"12px"}}>5</MenuItem>
            <MenuItem value={10} sx={{fontSize:"12px"}}>10</MenuItem>
            <MenuItem value={25} sx={{fontSize:"12px"}}>25</MenuItem>
            <MenuItem value={50} sx={{fontSize:"12px"}}>50</MenuItem>            
            <MenuItem value={100} sx={{fontSize:"12px"}}>100</MenuItem>
            <MenuItem value={250} sx={{fontSize:"12px"}}>250</MenuItem>
            <MenuItem value={500} sx={{fontSize:"12px"}}>500</MenuItem>
            <MenuItem value={1000} sx={{fontSize:"12px"}}>1000</MenuItem>
          </Select>
              </div>

              <div>
              {dataLength > 0 ? ( <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility: nextShow ? "visible" : "hidden",
                      float: "hidden",
                    }}
                  >
                    Next
                  </button>) : ""}
              </div>
              
             
            </div>
          </Col>
        </Row>

        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}

export default NewRideList;
